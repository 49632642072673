<template>
  <div>
    <div class="auth-page__header">
      <div class="auth-page__title">{{ $t("renewPasswordTitle") }}</div>
    </div>
    <div class="auth-confirm__subtitle">
      {{ $t("renewPasswordSMSText") }}
    </div>
    <form
      id="reset-password-form"
      method="post"
      novalidate="true"
      class="auth-page__form"
      @submit="onSubmit"
    >
      <easy-input
        v-model="phoneNumber"
        :label="$t('phoneReg')"
        defaultType="tel"
        isPhoneNumber
        :mask="phoneMask"
        :onEnter="onSubmit"
        :errorText="$t('errPhone')"
        :isError="isSubmitted && this.unmaskedPhoneNumber.length < 8"
      />
      <button
        :class="{ disabled: isNotValid, isLoading: isLoading }"
        class="btnWideRound"
      >
        {{ $t("btnNext") }}
      </button>
    </form>
  </div>
</template>

<script>
import EasyInput from "@/components/common/EasyInput";
import eventsRegister from "@/services/events-register";
import { mapActions, mapGetters } from "vuex";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "ResetPasswordForm",
  components: {
    EasyInput,
  },
  data() {
    return {
      phoneNumber: localStorage.getItem("loginPhone") || "",
      countryCodes: {
        UA: 285,
        USA: 284,
      },
      isSubmitted: false,
      isLoading: false,
      phoneMask: {
        mask: "+PPPPPPPPPPPPPP",
        tokens: {
          P: { pattern: /^[0-9]*\.?[0-9]*$/ },
        },
      },
    };
  },
  computed: {
    ...mapGetters("root", ["userIPData"]),
    isNotValid() {
      return this.unmaskedPhoneNumber.length < 8;
    },
    unmaskedPhoneNumber() {
      return this.phoneNumber.replace(/^(\+)|\D/g, "$1");
    },
    userCountry() {
      return this.countryCodes[this.userIPData.countryCode] || 285;
    },
  },
  methods: {
    ...mapActions("user", ["resetGetSMS"]),
    async onSubmit(e) {
      e.preventDefault();
      if (this.isLoading) return false;
      this.isSubmitted = true;
      let countryCode = 285;

      const phone = parsePhoneNumber(this.phoneNumber);
      if (phone.countryCallingCode) {
        const codes = {
          1: 284,
          380: 285,
        };

        countryCode = codes[phone.countryCallingCode];
      }
      console.log("parsePhoneNumber", parsePhoneNumber(this.phoneNumber));
      // return false;

      if (!this.isNotValid) {
        this.isLoading = true;
        try {
          await this.resetGetSMS({
            phoneNumber: this.unmaskedPhoneNumber,
            countryCode,
          });
          this.isLoading = false;
          this.$router.push({ name: "ResetPasswordConfirm" });
        } catch (error) {
          const message = error.Message
            ? this.$t("resetPasswordPhoneErr")
            : this.$t("serverError");
          this.$root.$emit(eventsRegister.addMessage, {
            type: "error",
            message,
          });
          this.isLoading = false;
        }
      }
      // this.$router.push("/sign-up/confirm");
    },
  },
};
</script>

<style></style>
